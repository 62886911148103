import index from './index.vue' //首页

import advertising from './advertising.vue' //全屏活动
import alertAdvertising from './alertAdvertising.vue' //弹窗活动



const templates = {
	index,

	advertising,
	alertAdvertising
}



export default  templates
